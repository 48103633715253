.react-calendar {
    background-color: #4F4F4F;
    border-radius: 10px;
    text-transform: uppercase;
    color: #FFFFFF;
    max-width: 350px;
}

.react-calendar__navigation {
    background-color: #4F4F4F;
    padding: 10px 20px;
    border-bottom: 2px solid #FF5253;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.react-calendar__navigation button {
    border: none;
    outline: none;
    background-color: transparent;
    color: #FFFFFF;
    text-transform: uppercase;
}

.react-calendar__viewContainer {
    padding: 20px;
}

.react-calendar__month-view__weekdays {
    text-align: center;
}

.react-calendar__tile--active abbr {
    background-color: #FF5253 !important;
    color: #FFFFFF;
    border-color: #FF5253 !important;
}

.react-calendar__month-view__days__day:focus,
.react-calendar__year-view__months__month:focus,
.react-calendar__decade-view__years__year:focus {
    border: none;
    outline: none;
}

.react-calendar__month-view__days__day,
.react-calendar__year-view__months__month,
.react-calendar__decade-view__years__year {
    outline: none;
    border: none;
    background-color: transparent;
    margin-top: 10px;
    padding: 0;
}

.react-calendar__month-view__days__day abbr,
.react-calendar__year-view__months__month abbr,
.react-calendar__decade-view__years__year abbr {
    display: block;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    text-transform: uppercase;
}

.react-calendar__tile--hasActive abbr {
    background-color: #FF5253;
    border-color: #FF5253;
}

.react-calendar__month-view__days__day--neighboringMonth abbr {
    color: #7f8c8d;
    border-color: #7f8c8d;
}